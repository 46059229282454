const hamilton = {
  logo: "hamilton-logo.png",
  backgroundImage: "hamilton-background",
  primaryColor: "hamilton-blue",
  primaryColorLight: "hamilton-blue-lighter",
  secondaryColor: "hamilton-green",
  secondaryColorLight: "hamilton-green-lighter",
  inputColor: "gray-600",
  inputColorHover: "gray-500",
  inputColorLight: "gray-200",
  inputColorLightest: "gray-100",
};

const defaultCouncil = {
  logo: "",
  backgroundImage: "hamilton-background",
  primaryColor: "indigo-700",
  primaryColorLight: "indigo-600",
  secondaryColor: "hamilton-green",
  secondaryColorLight: "hamilton-green-lighter",
  inputColor: "indigo-700",
  inputColorHover: "indigo-600",
  inputColorLight: "indigo-200",
  inputColorLightest: "indigo-100",
};

const flavourConfig = process.env.REACT_APP_FLAVOUR === "hamilton" ? hamilton : defaultCouncil;

export default flavourConfig;
