const BusinessCustomerRoute: React.FC = () => {
  return (
    <div className="pt-4 pb-4 xl:pt-5 x:pb-10 mx-auto w-full xl:w-8/12 h-full flex flex-col items-start xl:items-center">
      <h1 className="body-heading-font text-left xl:text-center">
        Residential Household Waste Only
      </h1>
      <p className="mt-4 body-subheading-font text-left xl:text-center">
        This event is funded by the Hamilton City Council and the Waikato Regional Council. It is
        open to local households to dispose of their hazardous waste. Businesses are expected to use
        a paid option.
      </p>
      <p className="w-full mt-4 body-subheading-font text-left xl:text-center">
        You can contact <span className="chem-style">Chem</span>
        <span className="waste-style">Waste</span> to get quote:
      </p>
      <p className="mt-4 w-full body-subheading-font text-center">
        <a href="mailto:CWAk@envirowaste.co.nz" className="underline">
          CWAk@envirowaste.co.nz
        </a>
        <br />
        <a href="tel:800 246 978" className="underline">
          0800 246 978
        </a>

        <br />
        <a href="tel:09 634 6198" className="underline">
          09 634 6198
        </a>
      </p>
    </div>
  );
};

export default BusinessCustomerRoute;
