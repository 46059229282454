import React from "react";
import "./CircleSpinner.css";

const CircleSpinner: React.FC = () => {
  return (
    <div className="h-full bg-white">
      <div className="lds-ring flex flex-col items-center justify-center h-full">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default CircleSpinner;
