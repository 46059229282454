const Footer: React.FC = () => {
  return (
    <div className="relative bg-white h-40 border-t border-gray-300">
      <div className="mt-12 mx-auto w-11/12 lg:10/12 xl:9/12 2xl:w-8/12 flex flex-row items-center justify-center lg:justify-end">
        <ul className="form-subheading-font text-center lg:text-right">
          <li className="">
            <a
              href="https://www.envirowaste.co.nz/assets/Uploads/Privacy-Policy-FINAL.pdf"
              className=""
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="">
            <a href="mailto:CWAK@envirowaste.co.nz" className="">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <p className="absolute left-1 bottom-1 text-xs text-gray-300">1.0.0.1</p>
    </div>
  );
};

export default Footer;
