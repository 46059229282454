import React from "react";
import { ITooltip } from "../../core/types/types";
import flavourConfig from "../../environment/flavourConfig";

const Tooltip: React.FC<ITooltip> = ({ showTooltip, updateShowTooltip, pathname }) => {
  const getTooltipText = (pathname: string) => {
    switch (pathname) {
      case "/customer-form":
        return "We need your details in order to ensure that you are signing up in the right collection area. Contact 0800 246 978 if you need help.";
      case "/waste-form":
        return "You can add multiple items to your disposal list. Just make sure you've added them all before clicking 'Submit'. Contact 0800 246 978 for assistance.";
      case "/confirmation":
        return "There's nothing more to be done. You can contact us on 0800 246 978 if you have any further questions.";
      default:
        return "This is where you can find the information you need. Contact 0800 246 978 if you still have questions.";
    }
  };

  return (
    <div className="relative mt-0 ">
      <div
        className="mb-3 ml-2 cursor-pointer select-none"
        onClick={() => updateShowTooltip(!showTooltip)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            updateShowTooltip(!showTooltip);
          }
        }}
      >
        <svg
          aria-haspopup="true"
          xmlns="http://www.w3.org/2000/svg"
          className=""
          width={23}
          height={23}
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#717f94"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <circle cx={12} cy={12} r={9} />
          <line x1={12} y1={8} x2="12.01" y2={8} />
          <polyline points="11 12 12 12 12 16 13 16" />
        </svg>
      </div>
      {showTooltip && (
        <div
          role="tooltip"
          className="z-20 absolute transition duration-150 ease-in-out -right-10 top-0 mt-8 shadow-lg pt-4 pr-5 pl-3 pb-3 bg-white rounded w-56 xl:w-72"
        >
          <svg
            className="absolute top-0 -mt-2 right-11"
            width="16px"
            height="8px"
            viewBox="0 0 16 8"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
              <g id="Tooltips-" transform="translate(-93.000000, -355.000000)" fill="#FFFFFF">
                <g id="Group-3-Copy-3" transform="translate(76.000000, 331.000000)">
                  <polygon
                    id="Triangle"
                    transform="translate(25.000000, 28.000000) rotate(-360.000000) translate(-25.000000, -28.000000) "
                    points="25 24 33 32 17 32"
                  />
                </g>
              </g>
            </g>
          </svg>
          <p className="body-subheading-font leading-5 pb-1">{getTooltipText(pathname)}</p>
          <p
            className={`progress-bar-font text-${flavourConfig.primaryColor} cursor-pointer`}
            onClick={() => updateShowTooltip(!showTooltip)}
          >
            Got it
          </p>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
