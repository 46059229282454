import React from "react";
import { IWasteTable, WasteItem } from "../../core/types/types";
import flavourConfig from "../../environment/flavourConfig";

const WasteTable: React.FC<IWasteTable> = ({
  wasteItemList,
  handleRemoveWasteItem,
}: IWasteTable) => {
  const renderList = (item: WasteItem, index: number) => {
    return (
      <tr key={index} className="h-24 border-gray-300 border-b">
        <td className="pl-6 pr-6">
          <table>
            <tbody className="text-left body-input-font">
              <tr className="flex justify-start items-center h-full">
                <td
                  className={`rounded-full whitespace-nowrap body-input-font leading-4 py-2 px-5 bg-${flavourConfig.inputColorLight} text-${flavourConfig.inputColor}`}
                >
                  {item.WasteType}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        <td className="pr-6 body-input-font text-left leading-4">{`${item.ContainerPackageSize} ${item.ContainerPackageSizeType}`}</td>
        <td className="pr-6 body-input-font text-left leading-4">{item.NumOfContainers}</td>
        <td className="pr-6 body-input-font text-left leading-4">{`${item.ApproximateAmount} ${item.ApproximateAmountType}`}</td>
        <td className="pr-6 body-input-font text-left leading-4">{item.Description}</td>
        <td className="pr-6 body-input-font text-left leading-4">
          <div className="flex items-center">
            <button
              type="button"
              className="rounded border border-transparent focus:outline-none focus:border-gray-800 focus:shadow-outline-gray"
              onClick={() => handleRemoveWasteItem(index)}
            >
              <div className="p-2 bg-gray-100 hover:bg-gray-200 rounded cursor-pointer text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1={4} y1={7} x2={20} y2={7} />
                  <line x1={10} y1={11} x2={10} y2={17} />
                  <line x1={14} y1={11} x2={14} y2={17} />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
              </div>
            </button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="mb-4 w-full">
      <div className="mx-auto container bg-white border-gray-300 shadow ">
        <div className="w-full overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr
                className={`w-full h-16 border-gray-300 border-b py-8 bg-${flavourConfig.inputColorLightest}`}
              >
                <th className="pl-6 pr-6 whitespace-nowrap text-left w-1/5 body-font">
                  Waste type
                </th>
                <th className="pr-6 whitespace-nowrap text-left w-1/5 body-font">Container size</th>
                <th className="pr-6 whitespace-nowrap text-left w-1/5 body-font">
                  No. of containers
                </th>
                <th className="pr-6 whitespace-nowrap text-left w-1/5 body-font">
                  Approximate amount
                </th>
                <th className="pr-6 whitespace-nowrap text-left w-1/5 body-font">Description</th>
                <th className="pr-6 whitespace-nowrap text-left w-1/5 body-font"></th>
              </tr>
            </thead>
            <tbody>
              {wasteItemList && wasteItemList?.length > 0 && wasteItemList.map(renderList)}
            </tbody>
          </table>
          {wasteItemList && wasteItemList?.length === 0 && (
            <p className="body-input-font pl-6 py-4">Use the form above to add your items...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default WasteTable;
