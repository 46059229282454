import { useEffect } from "react";

const NotFound: React.FC = () => {
  useEffect(() => {
    window.location.href = "/";
  }, []);
  return <div className=""></div>;
};

export default NotFound;
