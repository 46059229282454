const local = {
  requireLogin: false,
  apiBaseUrl: "http://localhost:7071/",
  keys: {
    getEventsKey: "",
    createCustomerKey: "",
    createCustomerLinesKey: "",
  },
};

const dev = {
  requireLogin: true,
  apiBaseUrl: "https://environz-api-dev.azurewebsites.net/API/",
  keys: {
    getEventsKey: "bO3ppF90cx6HCLd/ziQKUaubZg339OvyiEjS6pBaHwEIRfMMTjlVSw==",
    createCustomerKey: "jwZX1VrPhhg5Be9A1E43kUCprfLn/p6FI6fbOIwc2gWYRdX72bKxDw==",
    createCustomerLinesKey: "TQ6OXaFEz9d0fHqXrGqPQGsirVM5EwBr5zndaz6AuryWn/ouRYgGsA==",
  },
};

const prod = {
  requireLogin: false,
  apiBaseUrl: "https://environz-api.azurewebsites.net/API/",
  keys: {
    getEventsKey: "AadB7wp9HMM5RJiLZHOjFwWq/y87N6NftKkZ2ogt8UXnpswdBUpn2g==",
    createCustomerKey: "joFysf0clWknfl1qTY9KREakvFLIqteWFAUdMf8oGUxqlpSUIipCqg==",
    createCustomerLinesKey: "FF14lxUVbyBd/PmP1NpdQGAbIqTAzwB7JthtfO3fCXtZxGJOxJ7gDg==",
  },
};

const envConfig =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? prod
    : process.env.REACT_APP_ENVIRONMENT === "local"
    ? local
    : dev;

export default envConfig;
