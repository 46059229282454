import "./DotSpinner.css";

interface DotSpinnerProps {
  color?: string;
}

const DotSpinner: React.FC<DotSpinnerProps> = ({ color = "bg-black-primary" }: DotSpinnerProps) => {
  return (
    <div className={`w-full flex flex-row items-center justify-center`}>
      <div className="spinner flex flex-row items-center justify-center">
        <div className={`${color} bounce1`}></div>
        <div className={`${color} bounce2`}></div>
        <div className={`${color} bounce3`}></div>
      </div>
    </div>
  );
};

export default DotSpinner;
